import $ from 'jquery'
$(document).ready( initPostsSliders )

function initPostsSliders(){
  if ($('.js-latest-news-slider').length) {
    $('.js-latest-news-slider').slick({
      arrows: true,
      dots: true,
      infinite: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }

  if ($('.js-latest-research-slider').length) {
    $('.js-latest-research-slider').slick({
      arrows: true,
      dots: true,
      infinite: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }
}
